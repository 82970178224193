<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.display.mobile"
    max-width="850"
    scrollable
    persistent
    data-cy="ticket-fare-dialog"
  >
    <v-card>
      <v-card-title class="text-h5 pl-3 d-flex bg-systemPrimary dialog-title">
        <v-icon size="large" start color="white" icon="$ticketFareIcon" />
        <div class="align-self-center">{{ t('title') }}</div>
      </v-card-title>
      <v-card-text class="card-text px-11">
        <v-form ref="form" validate-on="lazy" @submit.prevent>
          <v-alert border="top" type="info" elevation="2" class="pt-6 mb-4" variant="text">
            {{ t('noPossibleDateInfo') }}
          </v-alert>
          <v-alert border="top" type="info" elevation="2" class="pt-6 mb-4" variant="text">
            {{
              t('pclMaxDaysBeforeStartInfo', {
                days: maxDaysBeforeTicketValidFrom,
              })
            }}
          </v-alert>
          <v-alert border="top" type="warning" elevation="2" class="pt-6 mb-4" variant="text">
            {{ t('beforeBuyWarning') }}
          </v-alert>
          <v-alert
            v-if="showTicketValidityExceededCardValidity"
            border="top"
            type="warning"
            elevation="2"
            class="pt-6 mb-4"
            variant="text"
          >
            {{ t('ticketValidityExceededCardValidity') }}
          </v-alert>
          <ProfileValidityInfoLight :card-profile="profileOne" :profile-number="1" />
          <ProfileValidityInfoLight :card-profile="profileTwo" :profile-number="2" />
          <v-row>
            <v-col :cols="$vuetify.display.mobile ? 12 : 6" class="pt-0 pb-0">
              <RDPDatePicker
                v-model="addTicketFareCommand.validFrom"
                :disabled-dates="disabledDates"
                :label="ts('validFrom')"
                :required="true"
                :min="validFrom.minDate.toString()"
                :max="validFrom.maxDate.toString()"
                :data-cy="cy('validFrom')"
                clearable
              />
            </v-col>
            <v-col class="pt-0 pb-0">
              <RDPDatePicker v-model="ticketFareValidTo" :label="ts('validTo')" disabled :data-cy="cy('validTo')" />
            </v-col>
            <div v-if="errors.validityError" class="ticket-fare-error-message v-messages__message pl-3 mt-0">
              {{ errors.validityError }}
            </div>
            <div
              v-if="errors.noPossibleCombinationError"
              class="ticket-fare-error-message v-messages__message pl-3 mt-0"
            >
              {{ errors.noPossibleCombinationError }}
            </div>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-3">
              <v-select
                :model-value="ticketCombination"
                :items="ticketCombinationsTranslated"
                :rules="validations.ticketCombination"
                :label="ts('ticketCombination')"
                :no-data-text="$t('common.noData')"
                :data-cy="cy('ticketCombination')"
                :error-messages="errors.ticketCombinationError"
                item-title="displayName"
                item-value="id"
                return-object
                variant="underlined"
                @update:model-value="ticketCombinationChanged"
              />
            </v-col>
            <div v-if="errors.collisionError" class="ticket-fare-error-message v-messages__message pl-3 mt-0">
              {{ errors.collisionError }}
            </div>
          </v-row>
          <v-row>
            <v-col cols="6" class="pt-0">
              <v-select
                v-model="addTicketFareCommand.zone"
                :items="zoneItems"
                :rules="validations.zone"
                :label="ts('zone')"
                :no-data-text="$t('common.noData')"
                :data-cy="cy('zone')"
                variant="underlined"
                @update:model-value="zoneChanged"
              />
              <div v-if="errors.priceListError" class="ticket-fare-error-message v-messages__message">
                {{ errors.priceListError }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="bold-text">
              <span>{{ ts('price') }}</span>
              <span class="ml-1 mr-1" :data-cy="cy('price')">
                {{ toPrice(ticketFarePrice.toString()) }}
              </span>
              <span>{{ $t('common.currency') }}</span>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-6 pb-6">
        <v-spacer v-if="!$vuetify.display.mobile" />
        <div :class="buttonsClass">
          <v-btn color="buttonWarning" :data-cy="cy('cancel-button')" variant="elevated" @click="closeDialog">
            <template #prepend>
              <v-icon>mdi-cancel</v-icon>
            </template>
            {{ $t('buttons.close') }}
          </v-btn>
          <ConfirmButton
            v-if="!$vuetify.display.mobile"
            :confirm="confirmDialog"
            :text="$t('buttons.confirmAndClose')"
            icon="mdi-check"
            :data-cy="cy('confirm-button')"
          />
          <ConfirmButton
            :confirm="confirmAndGoToShoppingCart"
            :text="confirmAndRedirectText"
            icon="mdi-cart-arrow-down"
            :data-cy="cy('confirm-button-and-redirect-to-shopping-cart')"
          />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Ref, Watch } from 'vue-facing-decorator';
import ConfirmButton from '@/components/common/RDPConfirmButton.vue';
import { FormInterface } from '@/components/common/FormInterface';
import ticketCombinationService from '@/services/api/ticketCombinationService';
import {
  AddTicketFareCommand,
  CardProfileVm,
  EnumDto,
  EnumsDto,
  GlobalSettingDto,
  PriceListZoneDto,
  TicketCombinationDto,
  TicketCombinationQuery,
  TicketFareDto,
} from '@/api';
import { requiredValidator } from '@/utils/validators';
import { getTicketCombinationDisplayName, getZoneDisplayName } from '@/utils/i18n';
import RDPDatePicker from '@/components/common/RDPDatePicker/RDPDatePicker.vue';
import shoppingCartService from '@/services/api/shoppingCartService';
import Paths from '@/constants/Paths';
import ShoppingCartModule from '@/store/modules/shoppingCart';
import customerService from '@/services/api/customerService';
import {
  DATE_FORMAT,
  formatDate,
  getLaterDate,
  isBetween,
  isDateOneAfterDateTwo,
  isDateOneSameOrAfterDateTwo,
  isDateOneSameOrBeforeDateTwo,
} from '@/utils/dateTime';
import tariffProfileService from '@/services/api/tariffProfileService';
import priceListService from '@/services/api/priceListService';
import ComponentBase from '@/components/common/ComponentBase';
import ProfileValidityInfoLight from '@/components/common/RDPCustomerDetail/ProfileValidityInfoLight.vue';
import UserAccountModule from '@/store/modules/userAccount';
import globalSettingService from '@/services/api/globalSettingService';
import { GlobalSetting } from '@/constants/GlobalSettting';
import { isArrayEmpty } from '@common/utils/isDefined';
import {
  computeCalendarTicketValidity,
  computeFixedTicketValidFrom,
  computeSlidingTicketValidity,
} from '@/utils/tariffProfile';
import { TICKET_FARE_MIN_VALID_FROM } from '@/config/config';
import { getTicketMinValidFrom } from '@common/utils/ticketMinValidFrom';
import SystemConfigurationModule from '@/store/modules/systemConfiguration';
import { MinValidFrom } from '@/models/enums/MinValidFrom';
import ToastModule from '@/store/modules/toast.ts';
import * as filters from '@/filters';
import { getApiError } from '@/utils/toast.ts';
import { ApiErrorInterface } from '@/models/ApiClientError.ts';
import { TicketFareIsicPriceQuery } from '@/api/models/TicketFareIsicPriceQuery';
import ZoneService from '@/services/api/zoneService.ts';
import { addDays, differenceInDays, format, isAfter, startOfDay, toDate, isBefore } from 'date-fns';

interface TicketCombinationVm
  extends Omit<TicketCombinationDto, 'mapSimpleProperties' | 'mapNullableProperties' | 'mapNumberProperties'> {
  displayName: string;
}

interface ZoneItem {
  zone: {
    displayName: string;
    name: string;
    id: string;
  };
}

@Component({
  components: {
    ConfirmButton,
    RDPDatePicker,
    ProfileValidityInfoLight,
  },
})
export default class TicketFareDialog extends ComponentBase {
  @Ref()
  readonly form!: FormInterface;

  globalSettingList: GlobalSettingDto[] = [];

  userAccountModule = UserAccountModule;
  systemConfigurationModule = SystemConfigurationModule;

  show = false;
  ticketFarePrice = 0;
  validFrom: {
    minDate: string;
    maxDate: string;
  } = {
    minDate: getTicketMinValidFrom(
      SystemConfigurationModule.configuration.ticketMinValidFrom as MinValidFrom,
    ).toISOString(),
    maxDate: '',
  };
  addTicketFareCommand = {
    /**
     * Ticket fare valid from.
     */
    validFrom: '',
  } as AddTicketFareCommand;
  /**
   * Ticket fare valid to.
   */
  ticketFareValidTo = '';
  ticketCombinationCollection: {
    /**
     *  Array of TicketCombinations
     */
    list: TicketCombinationDto[];
    /**
     *  Map of [id, TicketCombination]
     */
    map: Map<string, TicketCombinationDto>;
  } = {
    list: [],
    map: new Map(),
  };
  selectedPriceListZones: PriceListZoneDto[] = [];
  zoneDefaultSelectOptions: ZoneItem[] = [];
  validations = {
    ticketCombination: [(v: string) => requiredValidator(v)],
    validFrom: [(v: string) => requiredValidator(v)],
    zone: [(v: string) => requiredValidator(v)],
  };
  errors = {
    priceListError: '',
    validityError: '',
    ticketCombinationError: '',
    collisionError: '',
    noPossibleCombinationError: '',
  };
  selectedTariffProfileType: EnumDto | undefined;
  disabledDates: string[] = [];
  selectedPriceListId = '';

  dataCy = 'userAccountPage-ticketFareDialog';
  i18nGroupKey = 'userAccountPage.ticketFareDialog';

  /** **************************************************************************
   * GETTERS
   ************************************************************************** */
  get profileOne() {
    if ('profileOne' in this.userAccountModule.cardDetailData) {
      return this.userAccountModule.cardDetailData.profileOne;
    }

    return undefined;
  }

  get profileTwo() {
    if ('profileTwo' in this.userAccountModule.cardDetailData) {
      return this.userAccountModule.cardDetailData.profileTwo;
    }

    return undefined;
  }

  get buttonsClass() {
    return this.$vuetify.display.mobile ? 'd-flex flex-column ml-auto gap-1 ml-0' : '';
  }

  get confirmAndRedirectText() {
    const i18nKey = this.$vuetify.display.mobile ? 'addToShoppingCart' : 'confirmAndGoToShoppingCart';
    return this.$t(`buttons.${i18nKey}`);
  }

  get maxDaysBeforeTicketValidFrom(): number | undefined {
    return this.globalSettingList.find(
      (setting: GlobalSettingDto) => setting.name === GlobalSetting.MaxDaysBeforeTicketValidFrom,
    )?.value;
  }

  get showTicketValidityExceededCardValidity() {
    return (
      (this.userAccountModule.isCardTypeBank || this.userAccountModule.isCardTypeVirtual) &&
      isDateOneAfterDateTwo(this.ticketFareValidTo, this.userAccountModule.cardDetailData.validTo)
    );
  }

  translateZone(id: string) {
    return getZoneDisplayName(id);
  }

  /** DO NOT change this method to a getter - it won't work */
  getSelectedTicketCombination() {
    return this.ticketCombinationCollection.map.get(this.addTicketFareCommand.ticketCombinationId);
  }

  /** DO NOT change this method to a getter - it won't work */
  getSelectedPriceListZone() {
    return this.selectedPriceListZones.find(
      (tcPrice: PriceListZoneDto) => tcPrice.zone.name === this.addTicketFareCommand.zone,
    );
  }

  /** **************************************************************************
   * CREATED
   ************************************************************************** */

  async created() {
    try {
      this.globalSettingList = await globalSettingService.getGlobalSetting();
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.globalSettingLoadingFailed`),
      });
    }
    try {
      this.zoneDefaultSelectOptions = (await ZoneService.getZonePublicList()).map(zoneDto => {
        return { zone: { displayName: zoneDto.displayName, name: zoneDto.name, id: zoneDto.id } };
      });
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.publicZoneListLoadingFailed`),
      });
      this.zoneDefaultSelectOptions = [];
    }
  }

  /** **************************************************************************
   * WATCH
   ************************************************************************** */

  @Watch('addTicketFareCommand.validFrom')
  async validFromChanged() {
    this.clearCustomErrors();
    await this.computeTicketFareValidFromAndValidTo();
    await this.loadTicketCombinationList();
    this.clearSelectedTCIfNotExistInList();
    this.checkTFValidToIsValid();
    this.checkCustomerProfileIsValid();
    await this.updatePriceListZones();
  }

  async zoneChanged() {
    await this.setTicketFarePrice();
  }

  async ticketCombinationChanged(item: TicketCombinationVm) {
    this.clearCustomErrors();
    this.addTicketFareCommand.ticketCombinationId = item.id;
    this.selectedTariffProfileType = item.tariffProfile.type;
    this.selectedPriceListId = item.priceList.id;
    await this.updatePriceListZones();
  }

  async updatePriceListZones() {
    if (!this.selectedPriceListId) {
      return;
    }

    try {
      await this.loadSelectedPriceListZones();

      if (!this.addTicketFareCommand.zone) {
        this.addTicketFareCommand.zone = this.selectedPriceListZones[0].zone.name;
      }

      if (this.addTicketFareCommand.zone && !this.getSelectedPriceListZone()) {
        this.setDefaultTicketZone();
      }

      this.setValidFromMinAndMax();

      await this.computeTicketFareValidFromAndValidTo();
      this.checkTFValidToIsValid();
      this.checkCustomerProfileIsValid();

      await this.setTicketFarePrice();
      this.setNotAllowedDatesForDatePicker();
    } catch (e) {
      ToastModule.error({
        message: this.$t(`${this.i18nGroupKey}.errors.ticketCombinationPriceLoadingFailed`),
      });
    }
  }

  setDefaultTicketZone() {
    if (this.zoneItems.length === 1) {
      this.addTicketFareCommand.zone = this.zoneItems[0].value as string;
    } else {
      this.addTicketFareCommand.zone = '';
    }
  }

  /** **************************************************************************
   * DIALOG
   ************************************************************************** */

  async openDialog() {
    try {
      this.clearCustomErrors();
      this.setValidFromMinAndMax();
      await this.loadTicketCombinationList();

      const lastTicket = await this.getLastTicket();
      this.setTicketCombinationValidFrom(lastTicket);

      if (lastTicket) {
        const lastTicketCombination = this.ticketCombinationCollection.map.get(lastTicket.ticketCombination.id);

        // Set ticket fields for form only if the last TC is present in the current list.
        if (lastTicketCombination) {
          this.addTicketFareCommand.zone = lastTicket.zones[0].name;
          this.addTicketFareCommand.ticketCombinationId = lastTicketCombination.id;

          this.clearSelectedTCIfNotExistInList();
          await this.computeTicketFareValidFromAndValidTo();
          this.checkTFValidToIsValid();
          this.checkCustomerProfileIsValid();

          await this.ticketCombinationChanged(lastTicketCombination);
        } else {
          this.setDefaultTicketZone();
        }
      } else {
        this.setDefaultTicketZone();
      }

      this.setNotAllowedDatesForDatePicker();

      this.show = true;
    } catch (e) {
      ToastModule.error({
        message: this.t(`${this.i18nGroupKey}.ticketFareLoadingFailed`),
      });
    }
  }

  confirmAndGoToShoppingCart() {
    this.confirmDialog({ goToShoppingCart: true });
  }

  async confirmDialog({ goToShoppingCart } = { goToShoppingCart: false }) {
    if ((await this.form.validate()).valid && this.noCustomErrors()) {
      try {
        this.addTicketFareCommand.cardId = this.userAccountModule.cardDetailData.id;
        await shoppingCartService.addTicketFare({
          ...this.addTicketFareCommand,
        });

        await ShoppingCartModule.updateShoppingCart();
        await this.notifySuccess({ goToShoppingCart });
      } catch (e) {
        ToastModule.error({
          message: getApiError(
            e as ApiErrorInterface,
            this.i18nGroupKey,
            'saveFailed',
            this.getErrorPayloadAfterConfirm(e),
          ),
        });
      }
    }
  }

  closeDialog() {
    this.show = false;
    this.resetForm();
  }

  /** **************************************************************************
   * HELPER FUNCTIONS
   ************************************************************************** */

  /**
   * Gets customer ticket fare list and finds the last ticket or returns undefined if not found.
   */
  async getLastTicket(): Promise<TicketFareDto | undefined> {
    const customerCardQuery = {
      sortDesc: true,
      sortBy: 'validTo',
      limit: 1,
      stateIds: [EnumsDto.ticketStates.ACTIVE, EnumsDto.ticketStates.EXPIRED, EnumsDto.ticketStates.FUTURE],
    };
    const response = await customerService.getCustomerTicketFareList(
      this.userAccountModule.cardDetailData.customer.id,
      customerCardQuery,
    );

    const customerTicketFareList = response.data;
    return isArrayEmpty(customerTicketFareList) ? undefined : customerTicketFareList[0];
  }

  /**
   * Sets validFrom for the date picker by the last ticket or as today.
   */
  setTicketCombinationValidFrom(lastTicket?: TicketFareDto) {
    const today: string = format(new Date(), DATE_FORMAT);
    this.addTicketFareCommand.validFrom = getTicketMinValidFrom(
      this.systemConfigurationModule.configuration.ticketMinValidFrom as MinValidFrom,
    ).toISOString();
    if (lastTicket) {
      const lastTicketValidToNextDay = addDays(lastTicket.validTo, 1);
      const maxDay = addDays(new Date(), this.maxDaysBeforeTicketValidFrom || 0);
      if (isAfter(lastTicketValidToNextDay, today) && isDateOneSameOrBeforeDateTwo(lastTicketValidToNextDay, maxDay)) {
        this.addTicketFareCommand.validFrom = format(lastTicketValidToNextDay, DATE_FORMAT);
      }
    }

    this.addTicketFareCommand.validFrom = format(
      getLaterDate(this.addTicketFareCommand.validFrom, this.validFrom.minDate.toString()),
      DATE_FORMAT,
    );
  }

  async loadTicketCombinationList() {
    const profile = this.getProfileOneOrTwoByValidFrom(this.addTicketFareCommand.validFrom)?.profile;
    const query = {
      sortDesc: false,
      sortBy: 'orderNumber',
      limit: 999,
      eshopSale: true,
      date: this.addTicketFareCommand.validFrom,
      typeName: TicketCombinationQuery.typeName.TIME,
      customerProfileId: profile?.id,
      reduced: profile ? undefined : false,
      customerProfileType: TicketCombinationQuery.customerProfileType.PERSONAL,
    };
    const response = await ticketCombinationService.getTicketCombinationList(query);

    this.setTicketCombinationCollection(response);
  }

  setTicketCombinationCollection(ticketCombinationList: TicketCombinationDto[]) {
    this.ticketCombinationCollection.list = ticketCombinationList;

    this.errors.noPossibleCombinationError = '';

    this.filterTicketCombinationByValidity();

    if (this.ticketCombinationCollection.list.length === 0) {
      this.errors.noPossibleCombinationError = this.t('errors.noPossibleCombinationError');
    }

    this.ticketCombinationCollection.map = new Map(this.ticketCombinationCollection.list.map(tc => [tc.id, tc]));
  }

  filterTicketCombinationByValidity() {
    if (!this.addTicketFareCommand.validFrom) {
      return;
    }

    this.ticketCombinationCollection.list = this.ticketCombinationCollection.list.filter(this.tariffProfileValidity);
  }

  tariffProfileValidity(ticketCombination: TicketCombinationDto) {
    const { tariffProfile } = ticketCombination;
    let validFrom = this.addTicketFareCommand.validFrom as string;
    let validTo = '';
    if (tariffProfile.type.name === EnumsDto.tariffProfileTypes.SLIDING) {
      [validFrom, validTo] = computeSlidingTicketValidity(
        validFrom,
        tariffProfile.periodQuantity || 0,
        tariffProfile.period,
      );
    }

    if (tariffProfile.type.name === EnumsDto.tariffProfileTypes.CALENDAR) {
      [validFrom, validTo] = computeCalendarTicketValidity(
        validFrom,
        tariffProfile.periodQuantity || 0,
        tariffProfile.period,
      );
    }

    if (tariffProfile.type.name === EnumsDto.tariffProfileTypes.FIXED) {
      [validFrom, validTo] = computeFixedTicketValidFrom(
        validFrom,
        tariffProfile.fixedValidFromDay,
        tariffProfile.fixedValidFromMonth,
        tariffProfile.fixedValidToDay,
        tariffProfile.fixedValidToMonth,
      );
    }
    const minDate = getTicketMinValidFrom(
      this.systemConfigurationModule.configuration.ticketMinValidFrom as MinValidFrom,
    ).toISOString();
    const profile = this.getProfileOneOrTwoByValidFrom(validFrom);
    const maxDate = this.findMaxValidTo(validFrom as string, profile);

    return (
      isDateOneSameOrAfterDateTwo(validFrom, minDate) &&
      isDateOneSameOrAfterDateTwo(validFrom, profile?.validFrom) &&
      this.isInCardValidity(validFrom) &&
      isDateOneSameOrBeforeDateTwo(validTo, maxDate)
    );
  }

  findMaxValidTo(ticketValidFrom: string, profile?: { validTo: string }): string {
    const daysToCompare: number[] = [];
    if (profile) {
      const toProfileEndInDays = differenceInDays(startOfDay(profile.validTo), startOfDay(ticketValidFrom));

      daysToCompare.push(toProfileEndInDays);
    }
    /*const toCardEndInDays = differenceInDays(
      startOfDay(this.userAccountModule.cardDetailData.validTo),
      startOfDay(ticketValidFrom),
    );*/

    if (!this.userAccountModule.isCardTypeBank && !this.userAccountModule.isCardTypeVirtual) {
      const toCardEndInDays = differenceInDays(this.userAccountModule.cardDetailData.validTo, ticketValidFrom);
      daysToCompare.push(toCardEndInDays);
    }

    const closesTicketToDate = this.findClosestActiveTicketFare(ticketValidFrom);
    if (closesTicketToDate) {
      const toNextTicketFareStartInDays = differenceInDays(
        startOfDay(closesTicketToDate.validFrom),
        startOfDay(ticketValidFrom),
      );
      daysToCompare.push(toNextTicketFareStartInDays);
    }

    const minDayToCollision = Math.min(...daysToCompare);

    return format(addDays(ticketValidFrom, minDayToCollision), DATE_FORMAT);
  }

  findClosestActiveTicketFare(date: string): TicketFareDto {
    return this.userAccountModule.cardDetailData.ticketFares
      .filter((ticket: TicketFareDto) => {
        if (
          !ticket.cancelled &&
          ticket.customerProfile.checkTicketCollision &&
          ticket.active &&
          (isBefore(date, ticket.validFrom as string) ||
            (isBefore(ticket.validFrom as string, date) && isBefore(date, ticket.validTo as string)))
        ) {
          return ticket;
        }
      })
      .sort((a, b) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime())[0];
  }

  isInCardValidity(date: string) {
    return isBetween(
      date,
      this.userAccountModule.cardDetailData.validFrom,
      this.userAccountModule.cardDetailData.validTo,
    );
  }

  /**
   * Get profileOne or profileTwo if the selected validFrom (from DatePicker) is in between one or the other profile's validity.
   * Returns undefined if the validFrom is not in any of those ranges.
   */
  getProfileOneOrTwoByValidFrom(validFrom: string | Date | undefined): CardProfileVm | undefined {
    const ticketCombinationValidFrom = validFrom ? validFrom : new Date();

    if (isBetween(ticketCombinationValidFrom, this.profileOne?.validFrom, this.profileOne?.validTo)) {
      return this.profileOne;
    } else if (isBetween(ticketCombinationValidFrom, this.profileTwo?.validFrom, this.profileTwo?.validTo)) {
      return this.profileTwo;
    }
    return undefined;
  }

  async loadSelectedPriceListZones() {
    if (!this.selectedPriceListId) {
      return;
    }

    const query = {
      date: this.addTicketFareCommand.validFrom,
    };

    this.selectedPriceListZones = await priceListService.getPriceListZones(this.selectedPriceListId, query);

    if (isArrayEmpty(this.selectedPriceListZones)) {
      this.errors.priceListError = this.t('errors.invalidPriceList');
    }
  }

  async setTicketFarePrice() {
    if (this.addTicketFareCommand.ticketCombinationId && this.addTicketFareCommand.zone) {
      const selectedTicketCombination = this.getSelectedTicketCombination();
      const priceListZone = this.getSelectedPriceListZone();

      if (selectedTicketCombination?.customerProfile.isic && priceListZone) {
        const ticketFareIsicPriceQuery: TicketFareIsicPriceQuery = {
          customerProfileId: selectedTicketCombination.customerProfile.id,
          priceListZoneId: priceListZone.id,
          validFrom: this.addTicketFareCommand.validFrom,
          validTo: this.ticketFareValidTo,
        };
        const calculatePrice = await customerService.calculateIsicTicketPrice(
          this.userAccountModule.cardDetailData.customer.id,
          ticketFareIsicPriceQuery,
        );
        this.ticketFarePrice = calculatePrice?.price || 0;
      } else {
        this.ticketFarePrice = priceListZone?.price || 0;
      }
    }
  }

  setValidFromMinAndMax() {
    const selectedTC = this.getSelectedTicketCombination();

    // set min date from system configuration or by validFrom from the selected TC if it's "later"
    let minDate = getTicketMinValidFrom(
      this.systemConfigurationModule.configuration.ticketMinValidFrom as MinValidFrom,
    ).toISOString();
    if (selectedTC?.validFrom && isAfter(selectedTC?.validFrom, minDate)) {
      minDate = selectedTC?.validFrom as string;
    }

    // set max date for validFrom by globalSettings or by validTo from the selected TC if it's "sooner"
    let maxDate = addDays(new Date(), this.maxDaysBeforeTicketValidFrom || 0).toISOString();
    if (selectedTC?.validTo && isBefore(selectedTC?.validTo, maxDate)) {
      maxDate = selectedTC?.validTo as string;
    }

    minDate = format(getLaterDate(TICKET_FARE_MIN_VALID_FROM, minDate), DATE_FORMAT);

    this.validFrom.minDate = minDate;
    this.validFrom.maxDate = maxDate;
  }

  setNotAllowedDatesForDatePicker() {
    const selectedTicketCombination = this.getSelectedTicketCombination();
    const selectedTicketCanHaveCollisionInThePast =
      this.selectedTariffProfileType && this.ticketCanHaveCollisionInThePast(this.selectedTariffProfileType);

    if (selectedTicketCombination && selectedTicketCombination.customerProfile.checkTicketCollision) {
      for (const ticket of this.userAccountModule.cardDetailData.ticketFares) {
        if (!ticket.cancelled && ticket.customerProfile.checkTicketCollision && ticket.active) {
          const customerTicketCanHaveCollisionInThePast =
            ticket.tariffProfile.type && this.ticketCanHaveCollisionInThePast(ticket.tariffProfile.type);
          let min: string | Date = ticket.validFrom;

          if (
            (selectedTicketCanHaveCollisionInThePast || customerTicketCanHaveCollisionInThePast) &&
            isBefore(ticket.validFrom, new Date())
          ) {
            min = new Date();
          }

          const max = ticket.validTo;

          if (isAfter(max, min)) {
            if (
              isBetween(this.addTicketFareCommand.validFrom, toDate(min), toDate(max)) ||
              isBetween(this.ticketFareValidTo, toDate(min), toDate(max))
            ) {
              this.errors.collisionError = this.t('errors.collisionErrorMessage');
            }

            while (isDateOneSameOrBeforeDateTwo(min, max)) {
              this.disabledDates.push(format(min, DATE_FORMAT));
              min = addDays(min, 1);
            }
          }
        }
      }
    } else {
      this.disabledDates = [];
    }
  }

  private ticketCanHaveCollisionInThePast(tariffProfileType: EnumDto): boolean {
    const selectedTariffProfileType = tariffProfileType?.name as EnumsDto.tariffProfileTypes;
    return [EnumsDto.tariffProfileTypes.FIXED, EnumsDto.tariffProfileTypes.CALENDAR].includes(
      selectedTariffProfileType,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorPayloadAfterConfirm(e: any) {
    let payload = {};
    if (e.data.metadata?.payload) {
      const { cardValidTo, profileValidFrom, profileValidTo, ticketCombinationValidFrom, ticketValidTo } =
        e.data.metadata.payload;
      payload = {
        cardValidTo: formatDate(cardValidTo),
        profileValidFrom: formatDate(profileValidFrom),
        profileValidTo: formatDate(profileValidTo),
        ticketCombinationValidFrom: formatDate(ticketCombinationValidFrom),
        ticketValidTo: formatDate(ticketValidTo),
      };
    }
    return payload;
  }

  async notifySuccess({ goToShoppingCart } = { goToShoppingCart: false }) {
    ToastModule.success({
      message: this.$t(`${this.i18nGroupKey}.saveSuccess`),
    });

    this.closeDialog();
    if (goToShoppingCart) {
      await this.$router.push(Paths.SHOPPING_CART);
    }
  }

  resetForm() {
    this.addTicketFareCommand = {
      validFrom: '',
    } as AddTicketFareCommand;
    this.errors.priceListError = '';
    this.validFrom.maxDate = '';
    this.validFrom.minDate = getTicketMinValidFrom(
      this.systemConfigurationModule.configuration.ticketMinValidFrom as MinValidFrom,
    ).toISOString();
    this.ticketFarePrice = 0;
    this.selectedPriceListId = '';
    this.clearCustomErrors();
    this.form.resetValidation();
  }

  /** **************************************************************************
   * VALIDATIONS AND CHECKS FOR ERRORS
   ************************************************************************** */

  noCustomErrors(): boolean {
    return (
      this.errors.validityError === '' && this.errors.ticketCombinationError === '' && this.errors.priceListError === ''
    );
  }

  clearCustomErrors() {
    this.errors.validityError = '';
    this.errors.ticketCombinationError = '';
    this.errors.priceListError = '';
    this.errors.collisionError = '';
    this.errors.noPossibleCombinationError = '';
  }

  setValidityError(errorMessage: string) {
    this.errors.validityError = errorMessage;
  }

  checkTFValidToIsValid() {
    let errorMessage;
    if (
      this.ticketFareValidTo !== '' &&
      isAfter(this.ticketFareValidTo, this.userAccountModule.cardDetailData.validTo)
    ) {
      errorMessage = this.t('errors.ticketFareValidToOutOfCardValidity', {
        ticketValidTo: formatDate(this.ticketFareValidTo),
        cardValidTo: formatDate(this.userAccountModule.cardDetailData.validTo),
      });
    } else {
      errorMessage = '';
    }
    this.setValidityError(errorMessage);
  }

  checkCustomerProfileIsValid() {
    let errorMessage;
    const selectedTC = this.getSelectedTicketCombination();
    const profile = this.getProfileOneOrTwoByValidFrom(this.addTicketFareCommand.validFrom)?.profile;
    if (
      selectedTC &&
      selectedTC.customerProfile.reduced &&
      this.ticketFareValidTo !== '' &&
      profile &&
      profile.validTo &&
      isAfter(this.ticketFareValidTo, profile.validTo)
    ) {
      errorMessage = this.t('errors.profileValidToErrorMessage', {
        ticketValidTo: formatDate(this.ticketFareValidTo),
        profileValidTo: formatDate(profile.validTo),
      });
      this.errors.ticketCombinationError = this.t('errors.ticketCombinationInvalid');
    } else {
      errorMessage = '';
    }
    this.setValidityError(errorMessage);
  }

  clearSelectedTCIfNotExistInList() {
    if (this.addTicketFareCommand.ticketCombinationId) {
      if (!this.getSelectedTicketCombination()) {
        this.addTicketFareCommand.ticketCombinationId = '';
        const errorMessage = this.t('errors.ticketValidFromOutOfProfileValidity', {
          ticketCombinationValidFrom: formatDate(this.addTicketFareCommand.validFrom),
        });
        this.setDefaultTicketZone();
        this.ticketFarePrice = 0;
        this.setValidityError(errorMessage);
      }
    }
  }

  async computeTicketFareValidFromAndValidTo() {
    const selectedTC = this.getSelectedTicketCombination();
    if (selectedTC && this.addTicketFareCommand.validFrom) {
      const validity = await tariffProfileService.getComputedValidity(
        selectedTC.tariffProfile.id,
        this.addTicketFareCommand.validFrom,
      );
      this.addTicketFareCommand.validFrom = validity.validFrom;
      this.ticketFareValidTo = validity.validTo as string;

      if (
        this.selectedTariffProfileType?.name === EnumsDto.tariffProfileTypes.FIXED &&
        isBefore(this.addTicketFareCommand.validFrom, new Date())
      ) {
        this.setValidityError(this.t('ticketValidFromIsInThePast'));
      } else {
        this.setValidityError('');
      }
    } else {
      this.ticketFareValidTo = '';
    }
  }

  get zoneItems() {
    const zones = !isArrayEmpty(this.selectedPriceListZones)
      ? this.selectedPriceListZones
      : this.zoneDefaultSelectOptions;
    return zones.map(zoneItem => {
      return { title: zoneItem.zone.displayName, value: zoneItem.zone.name };
    });
  }

  get ticketCombinationsTranslated(): TicketCombinationVm[] {
    return this.ticketCombinationCollection.list.map(
      (ticketCombination: TicketCombinationDto): TicketCombinationVm =>
        ({
          ...ticketCombination,
          displayName: getTicketCombinationDisplayName(ticketCombination.id),
        }) as TicketCombinationVm,
    );
  }

  get ticketCombination(): TicketCombinationVm {
    const currentTicketCombination = this.ticketCombinationCollection.list.find(
      ticket => ticket.id === this.addTicketFareCommand.ticketCombinationId,
    ) as TicketCombinationVm;

    if (currentTicketCombination) {
      currentTicketCombination.displayName = getTicketCombinationDisplayName(currentTicketCombination?.id);
    }

    return currentTicketCombination;
  }

  toPrice(value: string) {
    return filters.toPrice(value);
  }
}
</script>

<style scoped lang="scss">
.headline {
  color: white;
}

.card-text {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ticket-fare-error-message {
  color: #ff5252;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ticket-fare-warning-message {
  color: #ffb74d;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.bold-text {
  font-weight: bold;
  font-size: 120%;
}
</style>
